import Vue from 'vue'

import App from './App.vue'
import router from './router/index'

import { fireanalytics } from './store/firebase'
import store from './store/store'

import logger from './plugins/logger'
import vuetify from './plugins/vuetify'

import sw from './utils/service-worker'

import './registerServiceWorker'

import './plugins/idle'
import './plugins/longpress'
import './plugins/xlsx'
import './plugins/underscoreExtensions'

const { NODE_ENV, VUE_APP_GOOGLE_PROJECT, VUE_APP_VERSION } = process.env
const isProdDestination = VUE_APP_GOOGLE_PROJECT === 'iadops-prod'
const isProd = isProdDestination || NODE_ENV === 'production'

logger.sentry.install(Vue, router)
logger.handle.env('iAdOps', VUE_APP_VERSION, isProd)

Vue.config.devtools = !isProd
Vue.prototype.$analytics = fireanalytics

const vue = new Vue({
  router,
  store,

  computed: {
    prompt() {
      return this.isAppIdle ? 'inactive' : 'active'
    },
  },
  created() {
    store.dispatch('auth/watch')

    store.dispatch('layout/watch', router)
  },
  onIdle() {
    logger.console.info('User is inactive.')
  },
  onActive() {
    logger.console.info('User is active.')
  },

  vuetify,

  render(h) {
    return h(App)
  },
}).$mount('#app')

if ('serviceWorker' in navigator) {
  window.addEventListener('load', sw.register.bind(sw))
}

export default vue
